import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  PoweroffOutlined,
  DeleteOutlined,
  MailFilled,
} from "@ant-design/icons";
import { Button, Input } from "antd";
import axios from "axios";
import moment from "moment";

import {
  getAllUsers,
  logOutUser,
  logOutUserNoRefresh,
} from "../../redux/apiRequest";
import { createAxios } from "../../redux/createInstance";
import { logOutSuccess } from "../../redux/slice/authSlice";
import { toastErr, toastSuccess } from "../../redux/slice/toastSlice";
import { resetImg } from "../../redux/slice/userSlice";
import DrawerApp from "../../component/SupportTab/Drawer";
import { sampleMail } from "../../const/mail";
import "./profileUser.scss";

const { TextArea } = Input;

const Demo3D = () => {
  return (
    <>
      <div className="parent">
        <div className="card">
          <div className="logo">
            <span className="circle circle1" />
            <span className="circle circle2" />
            <span className="circle circle3" />
            <span className="circle circle4" />
            <span className="circle circle5">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 29.667 31.69"
                className="svg"
              >
                <path
                  id="Path_6"
                  data-name="Path 6"
                  d="M12.827,1.628A1.561,1.561,0,0,1,14.31,0h2.964a1.561,1.561,0,0,1,1.483,1.628v11.9a9.252,9.252,0,0,1-2.432,6.852q-2.432,2.409-6.963,2.409T2.4,20.452Q0,18.094,0,13.669V1.628A1.561,1.561,0,0,1,1.483,0h2.98A1.561,1.561,0,0,1,5.947,1.628V13.191a5.635,5.635,0,0,0,.85,3.451,3.153,3.153,0,0,0,2.632,1.094,3.032,3.032,0,0,0,2.582-1.076,5.836,5.836,0,0,0,.816-3.486Z"
                  transform="translate(0 0)"
                />
                <path
                  id="Path_7"
                  data-name="Path 7"
                  d="M75.207,20.857a1.561,1.561,0,0,1-1.483,1.628h-2.98a1.561,1.561,0,0,1-1.483-1.628V1.628A1.561,1.561,0,0,1,70.743,0h2.98a1.561,1.561,0,0,1,1.483,1.628Z"
                  transform="translate(-45.91 0)"
                />
                <path
                  id="Path_8"
                  data-name="Path 8"
                  d="M0,80.018A1.561,1.561,0,0,1,1.483,78.39h26.7a1.561,1.561,0,0,1,1.483,1.628v2.006a1.561,1.561,0,0,1-1.483,1.628H1.483A1.561,1.561,0,0,1,0,82.025Z"
                  transform="translate(0 -51.963)"
                />
              </svg>
            </span>
          </div>
          <div className="glass" />
          <div className="content">
            <span className="title">UIVERSE (3D UI)</span>
            <span className="text">
              Create, share, and use beautiful custom elements made with CSS
            </span>
          </div>
          <div className="bottom">
            <div className="social-buttons-container">
              <button className="social-button .social-button1">
                <svg
                  viewBox="0 0 30 30"
                  xmlns="http://www.w3.org/2000/svg"
                  className="svg"
                >
                  <path d="M 9.9980469 3 C 6.1390469 3 3 6.1419531 3 10.001953 L 3 20.001953 C 3 23.860953 6.1419531 27 10.001953 27 L 20.001953 27 C 23.860953 27 27 23.858047 27 19.998047 L 27 9.9980469 C 27 6.1390469 23.858047 3 19.998047 3 L 9.9980469 3 z M 22 7 C 22.552 7 23 7.448 23 8 C 23 8.552 22.552 9 22 9 C 21.448 9 21 8.552 21 8 C 21 7.448 21.448 7 22 7 z M 15 9 C 18.309 9 21 11.691 21 15 C 21 18.309 18.309 21 15 21 C 11.691 21 9 18.309 9 15 C 9 11.691 11.691 9 15 9 z M 15 11 A 4 4 0 0 0 11 15 A 4 4 0 0 0 15 19 A 4 4 0 0 0 19 15 A 4 4 0 0 0 15 11 z" />
                </svg>
              </button>
              <button className="social-button .social-button2">
                <svg
                  viewBox="0 0 512 512"
                  xmlns="http://www.w3.org/2000/svg"
                  className="svg"
                >
                  <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" />
                </svg>
              </button>
              <button className="social-button .social-button3">
                <svg
                  viewBox="0 0 640 512"
                  xmlns="http://www.w3.org/2000/svg"
                  className="svg"
                >
                  <path d="M524.531,69.836a1.5,1.5,0,0,0-.764-.7A485.065,485.065,0,0,0,404.081,32.03a1.816,1.816,0,0,0-1.923.91,337.461,337.461,0,0,0-14.9,30.6,447.848,447.848,0,0,0-134.426,0,309.541,309.541,0,0,0-15.135-30.6,1.89,1.89,0,0,0-1.924-.91A483.689,483.689,0,0,0,116.085,69.137a1.712,1.712,0,0,0-.788.676C39.068,183.651,18.186,294.69,28.43,404.354a2.016,2.016,0,0,0,.765,1.375A487.666,487.666,0,0,0,176.02,479.918a1.9,1.9,0,0,0,2.063-.676A348.2,348.2,0,0,0,208.12,430.4a1.86,1.86,0,0,0-1.019-2.588,321.173,321.173,0,0,1-45.868-21.853,1.885,1.885,0,0,1-.185-3.126c3.082-2.309,6.166-4.711,9.109-7.137a1.819,1.819,0,0,1,1.9-.256c96.229,43.917,200.41,43.917,295.5,0a1.812,1.812,0,0,1,1.924.233c2.944,2.426,6.027,4.851,9.132,7.16a1.884,1.884,0,0,1-.162,3.126,301.407,301.407,0,0,1-45.89,21.83,1.875,1.875,0,0,0-1,2.611,391.055,391.055,0,0,0,30.014,48.815,1.864,1.864,0,0,0,2.063.7A486.048,486.048,0,0,0,610.7,405.729a1.882,1.882,0,0,0,.765-1.352C623.729,277.594,590.933,167.465,524.531,69.836ZM222.491,337.58c-28.972,0-52.844-26.587-52.844-59.239S193.056,219.1,222.491,219.1c29.665,0,53.306,26.82,52.843,59.239C275.334,310.993,251.924,337.58,222.491,337.58Zm195.38,0c-28.971,0-52.843-26.587-52.843-59.239S388.437,219.1,417.871,219.1c29.667,0,53.307,26.82,52.844,59.239C470.715,310.993,447.538,337.58,417.871,337.58Z" />
                </svg>
              </button>
            </div>
            <div className="view-more">
              <button className="view-more-button">View more</button>
              <svg
                className="svg"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="m6 9 6 6 6-6" />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
// <img
//   src={`${process.env.REACT_APP_BACKEND_URL}/auth/user/avatar/${user._id}`}
//   className="w-full h-full"
//   onError={(e) => {
//     e.target.onerror = null; // ngăn chặn vòng lặp vô hạn nếu ảnh mặc định bị lỗi
//     e.target.src =
//       "https://scr.vn/wp-content/uploads/2020/07/Avatar-Facebook-tr%E1%BA%AFng.jpg"; // đường dẫn đến ảnh mặc định
//   }}
//   alt=""
// />

const DemoIMG = ({
  user,
  handleImagePreview,
  imagePreview,
  handleUploadImage,
}) => {
  return (
    <>
      <div className="card__img">
        <div className="flex flex-col justify-between card-image">
          <img
            src={
              imagePreview
                ? imagePreview
                : `${process.env.REACT_APP_BACKEND_URL}/auth/user/avatar/${user._id}`
            }
            className="w-full h-full"
            onError={(e) => {
              e.target.onerror = null; // ngăn chặn vòng lặp vô hạn nếu ảnh mặc định bị lỗi
              e.target.src =
                "https://scr.vn/wp-content/uploads/2020/07/Avatar-Facebook-tr%E1%BA%AFng.jpg"; // đường dẫn đến ảnh mặc định
            }}
            alt=""
          />
        </div>
        <div className="category"> {user.username} </div>
        <div className="heading">
          {user.email}

          <div className="">
            <div className="my-5 ">
              Change Photo
              <input
                className="w-auto flex md:w-[24rem] sm:w-[17rem]"
                id="avatar-upload"
                type="file"
                accept="image/*"
                onChange={handleImagePreview}
              />
              {imagePreview && (
                <Button
                  className="bg-green-500 w-[100px] flex items-center mt-[3rem]"
                  type=" primary"
                  onClick={handleUploadImage}
                >
                  Xác nhận
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

function UserInfor() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [openDrawer, setOpenDrawer] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [contentMail, setContentMail] = useState({
    title: "Thông báo về sự trở lại của trang web",
    content: sampleMail,
  });

  const user = useSelector((state) => {
    return state.auth.login?.currentUser;
  });
  let axiosJWT = createAxios(user, dispatch, logOutSuccess);
  const MAX_IMAGE_SIZE = 990000; // 1MB
  const MAX_IMAGE_WIDTH = 1000; // Giới hạn chiều rộng tối đa
  const MAX_IMAGE_HEIGHT = 1000;

  useEffect(() => {
    if (!user) {
      navigate("/auth/login");
    }
  }, [user]);

  // xử lí ảnh preview
  const handleImagePreview = (e) => {
    if (e.target.files && e.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement("canvas");
          let width = img.width;
          let height = img.height;
          if (width > MAX_IMAGE_WIDTH) {
            height *= MAX_IMAGE_WIDTH / width;
            width = MAX_IMAGE_WIDTH;
          }
          if (height > MAX_IMAGE_HEIGHT) {
            width *= MAX_IMAGE_HEIGHT / height;
            height = MAX_IMAGE_HEIGHT;
          }
          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, width, height);
          const dataUrl = canvas.toDataURL("image/jpeg", 0.7); // Giảm chất lượng ảnh xuống còn 70%
          const blob = dataURLtoBlob(dataUrl);
          setImagePreview(dataUrl);
        };
        img.src = event.target.result;
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  function dataURLtoBlob(dataURL) {
    const arr = dataURL.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }

  // hàm xử lí up load ảnh
  const handleUploadImage = async () => {
    if (!imagePreview) {
      console.log("No image selected.");
      return;
    }
    const formData = new FormData();
    const imgBlob = dataURLtoBlob(imagePreview);
    formData.append("avatar", imgBlob);
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/auth/user/edit`, formData, {
        headers: {
          token: `Bearer ${user.accessToken}`,
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      })
      .then((res) => {
        dispatch(resetImg(Math.random()));
        dispatch(toastSuccess(res.data));
        window.location.reload();
      })
      .catch((err) => {
        console.log("lỗi");
        dispatch(toastErr(err.response.data));
      });
  };

  // khi ng dùng đăng xuất
  const handleLogOutUser = () => {
    // hàm trêm có sử dụng refresh token
    // logOutUser(user.accessToken, user._id, dispatch, navigate, axiosJWT);
    logOutUserNoRefresh(dispatch, navigate);
  };

  // phần gửi mail
  const handleSendMail = () => {
    if (contentMail) {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/user/sendMail`,
          contentMail,
          {
            headers: {
              token: `Bearer ${user.accessToken}`,
            },
          }
        )
        .then((res) => {
          dispatch(toastSuccess(res?.data?.message));
        })
        .catch((err) => {
          dispatch(toastSuccess(err?.response?.data?.message));
        });
    }
  };

  return (
    <>
      {user && (
        <div className="bg-white mx-auto p-[3%] my-[3%] rounded-md w-[70%] md:w-full font-medium">
          <div>
            <div className="">
              <div className="">
                <div className="flex flex-row gap-10 mb-5 md:w-full">
                  {/* <Demo3D /> */}

                  {/* <div className=" md:h-[10rem] overflow-hidden">
                    <DemoIMG
                      user={user}
                      handleUploadImage={handleUploadImage}
                      imagePreview={imagePreview}
                      handleImagePreview={handleImagePreview}
                    />
                  </div> */}

                  <div className="h-[20rem] md:h-[10rem] md:w-[10rem] w-[20rem] overflow-hidden">
                    {imagePreview ? (
                      <img
                        src={imagePreview}
                        className="h-full"
                        alt="Avatar preview"
                      />
                    ) : (
                      <img
                        src={`${process.env.REACT_APP_BACKEND_URL}/auth/user/avatar/${user._id}`}
                        className="w-full h-full"
                        onError={(e) => {
                          e.target.onerror = null; // ngăn chặn vòng lặp vô hạn nếu ảnh mặc định bị lỗi
                          e.target.src =
                            "https://scr.vn/wp-content/uploads/2020/07/Avatar-Facebook-tr%E1%BA%AFng.jpg"; // đường dẫn đến ảnh mặc định
                        }}
                        alt=""
                      />
                    )}
                  </div>
                  <div className="my-5 ">
                    Change Photo
                    <input
                      className="w-auto flex md:w-[24rem] sm:w-[17rem]"
                      id="avatar-upload"
                      type="file"
                      accept="image/*"
                      onChange={handleImagePreview}
                    />
                    {imagePreview && (
                      <Button
                        className="bg-green-500 w-[100px] flex items-center mt-[3rem]"
                        type=" primary"
                        onClick={handleUploadImage}
                      >
                        Xác nhận
                      </Button>
                    )}
                  </div>
                </div>
              </div>
              <div className="">
                <div className="text-[#333]">
                  <h5>Vị trí của bạn</h5>
                  <h6 className="text-[#0062CC] ">
                    {user.isAdmin ? "Chủ trang(ADMIN)" : "Học viên"}
                  </h6>
                  {/* <p className="mt-10 text-[2rem] flex items-center">
                      Tổng số xu hiện có :
                      <span className="text-[red] text-[3rem] ml-8 flex gap-1 items-center">
                        {user.money}{" "}
                        <span className="text-[1.6rem]"> (Đang thử nghiệm)</span>
                      </span>
                    </p> */}
                </div>
              </div>
            </div>

            <div className="">
              <div className="">
                <div className="" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="home"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    <div className="row flex my-[1rem]">
                      <div className="">
                        <label className="font-bold">User Id : </label>
                      </div>
                      <div className="ml-6 ">
                        <p className="text-[#0062CC] font-bold">
                          {" "}
                          {user._id.substring(user._id.length - 10)}
                        </p>
                      </div>
                    </div>
                    <div className="row flex my-[1rem]">
                      <div className="">
                        <label className="font-bold">Name :</label>
                      </div>
                      <div className="ml-6 ">
                        <p className="text-[#0062CC] font-bold">
                          {user.username}
                        </p>
                      </div>
                    </div>
                    <div className="row flex my-[1rem]">
                      <div className="">
                        <label className="font-bold">Email :</label>
                      </div>
                      <div className="ml-6 ">
                        <p className="text-[#0062CC] font-bold">{user.email}</p>
                      </div>
                    </div>

                    <div className="row flex flex-col my-[1rem] items-start">
                      <div className="">
                        <label className="font-bold">Khóa học đã mua :</label>
                      </div>
                      <div className="w-full ml-6 mr-5">
                        <p className="text-[red]">
                          {!user.courses
                            ? "chưa có khóa học nào..."
                            : Object.keys(user.courses).map((course, index) => (
                                <div
                                  key={index}
                                  className="w-full row flex my-[1rem]"
                                >
                                  <div className="">
                                    <label className="font-bold">
                                      {course} :
                                    </label>
                                  </div>
                                  <div className="ml-6 ">
                                    <p className="text-[#0062CC] font-bold w-full flex">
                                      Thời hạn :{" "}
                                      {moment(
                                        user.courses[course]?.time
                                      ).format("DD/MM/YYYY HH:mm")}{" "}
                                      {new Date(user?.courses[course].time) <
                                        Date.now() && (
                                        <p className="ml-2 text-red-500">
                                          (Hết hạn)
                                        </p>
                                      )}
                                    </p>
                                  </div>
                                </div>
                              ))}
                        </p>
                      </div>
                    </div>
                    <div className=" flex my-[1rem]">
                      <div className="">
                        <label className="font-bold">
                          Thời gian gia nhập :
                        </label>
                      </div>
                      <div className="ml-6 ">
                        {moment(user.createdAt).format("DD/MM/YYYY HH:mm")}
                      </div>
                    </div>
                    <div className="  my-[1rem]">
                      <p className=" text-[1.6rem] gap-5  py-2">
                        Mọi người có thắc mắc gì hay là muốn em cập nhật thêm
                        phần nào cứ nhắn cho em qua fanpage
                        <a
                          className="text-blue-500 cursor-pointer ml-1 mr-1 hover:text-green-400 font-bold text-[1.7rem]"
                          href="https://www.facebook.com/profile.php?id=100090524688743"
                          alt="facebook"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Tại Đây.
                        </a>
                        Mua khóa học
                        <Link
                          to="/courses/buy/details"
                          className="pl-1 mr-1 font-bold text-blue-500 hover:text-green-500"
                        >
                          Tại Đây 😍.
                        </Link>
                        Cám ơn mọi người đã ghé thăm trang web❤️. Có lỗi bài nào
                        nhớ nhắc em nha
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-end">
            <Button
              className="bg-green-500 flex items-center mx-[1rem]"
              type="primary"
              icon={<PoweroffOutlined />}
              onClick={() => {
                handleLogOutUser();
                // deleteUser(user.accessToken, user._id, dispatch);
              }}
            >
              Đăng Xuất
            </Button>

            {user.isAdmin && (
              <Link to={`/auth/admin`}>
                <Button
                  className="flex items-center bg-green-500"
                  type="primary"
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    getAllUsers(user.accessToken, dispatch);
                  }}
                >
                  Admin
                </Button>
              </Link>
            )}
            {user.isAdmin && (
              <Button
                className="bg-green-500 flex items-center  mx-[1rem]"
                type="primary"
                icon={<MailFilled />}
                onClick={() => setOpenDrawer(true)}
              >
                Send Mail
              </Button>
            )}
            <DrawerApp
              title="Gửi mai cho người dùng"
              openDrawer={openDrawer}
              setOpenDrawer={setOpenDrawer}
            >
              <span className="py-2"> Tiêu đề :</span>
              <Input
                value={contentMail.title}
                placeholder="Điền tiêu đề tin nhắn"
                onChange={(e) =>
                  setContentMail({
                    ...contentMail,
                    title: e.target.value,
                  })
                }
              ></Input>
              <span className="py-2"> Nội dung :</span>
              <TextArea
                rows={13}
                placeholder="Điền tin nhắn gửi cho user"
                value={contentMail.content}
                onChange={(e) => {
                  setContentMail({
                    ...contentMail,
                    content: e.target.value,
                  });
                }}
              />
              <Button
                type="primary"
                className="mx-auto mt-6 bg-red-500 "
                onClick={handleSendMail}
              >
                Xác nhận gửi
              </Button>
            </DrawerApp>
          </div>
        </div>
      )}
    </>
  );
}

export default UserInfor;
