import React, { useEffect } from "react";

import "./userGraph.scss";

import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar, Pie } from "react-chartjs-2";
import {
  UserOutlined,
  EyeOutlined,
  LineChartOutlined,
} from "@ant-design/icons";

ChartJS.register(
  CategoryScale,
  ArcElement,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const UserGraph = ({ dataBuyCourse, listUsers }) => {
  const keys = ["n1", "n2", "n3", "n4", "n5", "tokutei"];

  const courseCounts =
    listUsers &&
    keys.reduce((acc, key) => {
      acc[key] = listUsers.filter((user) => user?.courses?.[key]).length;
      return acc;
    }, {});

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Bản đồ thống kê số tháng và số người mua trong năm 2023",
        font: {
          weight: "bold",
        },

        //   formatter: (value) => value, // Hiển thị giá trị nguyên thủy
      },
    },
  };
  const optionsHinhTron = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Thống kê số người dùng trên từng khóa",
        font: {
          weight: "bold",
        },

        //   formatter: (value) => value, // Hiển thị giá trị nguyên thủy
      },
    },
  };
  const testHinhTron = {
    labels: ["N1", "N2", "N3", "N4", "N5", "Tokutei"],
    datasets: [
      {
        label: "Số người dùng",
        data: Object.values(courseCounts),
        backgroundColor: ["red", "yellow", "blue", "orange", "pink", "black"],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
  // Mảng chứa tên các tháng trong năm
  const labels = [
    "Tháng 1",
    "Tháng 2",
    "Tháng 3",
    "Tháng 4",
    "Tháng 5",
    "Tháng 6",
    "Tháng 7",
    "Tháng 8",
    "Tháng 9",
    "Tháng 10",
    "Tháng 11",
    "Tháng 12",
  ];

  const months = Object.keys(dataBuyCourse?.dataBuyCourse);
  const monthValues = months.map(
    (month) => dataBuyCourse.dataBuyCourse[month]?.month
  );
  const peopleValues = months.map(
    (month) => dataBuyCourse.dataBuyCourse[month]?.people.length
  );
  const totalPeople = months
    .map((month) => dataBuyCourse.dataBuyCourse[month]?.people.length)
    .reduce((acc, month) => acc + month, 0);

  const data = {
    labels,
    datasets: [
      {
        label: "Số người mua",
        data: peopleValues,
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Số tháng mua",
        data: monthValues,
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };
  //format số tiền
  const VND = new Intl.NumberFormat("vi-VN", {
    style: "currency",
    currency: "VND",
  });

  return (
    <div>
      <div className="flex justify-around my-8 market-updates">
        <div className="grid grid-cols-2 gap-6 col-md-4 market-update-gd">
          <div className="market-update-block !mr-0 clr-block-1">
            <div className="col-md-8 market-update-left">
              <h3 className="flex items-center gap-1">
                {listUsers && listUsers.length} <UserOutlined />
              </h3>

              <h4>Tổng số tài khoản</h4>
              <p>2 Admin</p>
            </div>
            <div className="col-md-4 market-update-right"></div>
            <div className="clearfix"> </div>
          </div>
          <div className="market-update-block !mr-0 clr-block-2">
            <div className="col-md-8 market-update-left">
              <h3 className="flex items-center gap-1">
                {totalPeople || 0} <UserOutlined />
              </h3>
              <h4>Người dùng đã mua</h4>
              <p>~ {totalPeople - 3}</p>
            </div>
            <div className="col-md-4 market-update-right">
              <i className="fa fa-eye"> </i>
            </div>
          </div>
          {/* <div className="market-update-block !mr-0 clr-block-3">
            <div className="col-md-8 market-update-left">
              <h3 className="flex items-center gap-1">
                {dataBuyCourse?.month} <LineChartOutlined />
              </h3>
              <h4>Số tháng đã bán</h4>
            
            </div>
            <div className="col-md-4 market-update-right">
              <i className="fa fa-eye"> </i>
            </div>
            <div className="clearfix"> </div>
          </div>
          <div className="market-update-block !mr-0 clr-block-4">
            <div className="col-md-8 market-update-left">
              <h3 className="flex items-center gap-1">
                {VND.format(dataBuyCourse?.month * 20000)}
              </h3>
              <h4>Tổng thu nhập ước tính</h4>
            </div>
            <div className="col-md-4 market-update-right">
              <i className="fa fa-eye"> </i>
            </div>
            <div className="clearfix"> </div>
          </div> */}
        </div>

        <div className="col-md-4 market-update-gd">
          <Pie options={optionsHinhTron} data={testHinhTron} />
        </div>
      </div>

      <Bar options={options} data={data} />
    </div>
  );
};

export default UserGraph;
