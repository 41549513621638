import React from "react";
import { SmileOutlined } from "@ant-design/icons";
import { Result } from "antd";
import momo from "../../assets/img/momo.jpg";
import viettin from "../../assets/img/viettin.jpg";
import axios from "axios";
function BuyCourse() {
  const demohandle = () => {
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/user/create_payment_url`, {
      demo: "demmo",
    });
  };
  return (
    <div className="relative flex flex-col items-center bg-[#000836] py-[3rem] min-h-[400px]">
      <button onClick={demohandle}>DEMO</button>
      <div className="flex justify-center ssm:flex-col  ssm:h-full gap-[8rem] md:gap-[3rem] w-[90%] h-[400px] md:h-[300px] sm:h-[250px] mx-auto mb-[1rem]">
        <img
          className="h-full rounded-2xl ssm:w-[80%] ssm:mx-auto "
          src={momo}
          alt=""
        />
        <img
          className="h-full rounded-2xl ssm:w-[80%] ssm:mx-auto"
          src={viettin}
          alt=""
        />
      </div>
      <div
        className="flex
      ssm:flex-col-reverse
      justify-around mt-7 mx-auto w-[90%] mb-[1rem] p-[2rem] leading-4 shadow-desc bg-slate-100 "
      >
        <div className="flex flex-col justify-around">
          <span className="font-bold leading-5">
            <span className="text-black">&nbsp;Lưu ý:</span>
          </span>

          <br />
          <span className="text-[red] leading-7">
            » Nội dung chuyển khoản ghi rõ: Email hoặc tài khoản - Khóa học muốn
            mua. Ví dụ: nguyenvanAgmail.com - n3
          </span>
          <br />
          <span className="text-[red] leading-7">
            » Nếu muốn ngay lập tức, bạn có thể chụp bild và nhắn qua Fanpage
            <a
              className="text-blue-500 cursor-pointer ml-4 hover:text-green-400 font-bold text-[1.8rem]"
              href="https://www.facebook.com/profile.php?id=100090524688743"
              alt="facebook"
              target="_blank"
              rel="noreferrer"
            >
              Tại Đây
            </a>
          </span>
          <br />
          <span className="text-[#222222] leading-7">
            » Lưu ý : Bỏ ký tự đặc biệt @ trong địa chỉ mail vì 1 số ngân hàng
            không giao dịch được.
          </span>
          <br />
          <span className="text-[#222222] leading-7">
            » Chuyển thành thành công vui lòng nhắn admin với các phương thức ở
            cuối trang hoặc nháy vào nút tin nhắn ngay góc phải dưới màn hình.
          </span>
          <br />

          <span className="text-[#222222] leading-7">
            » Nếu lỡ không đính kèm thông tin thì xin vui lòng gửi kèm biên lai
            để chúng tôi xử lí.
          </span>
          <br />

          <span className="text-[#222222] leading-7">
            » Bạn cũng có thể liên hệ với chúng tôi qua messenger facebook ở góc
            dưới trái màn hình.
          </span>
        </div>
        <Result
          icon={<SmileOutlined />}
          extra={false}
          // title={<strong className="text-red-500">20k/tháng🔥 (ưu đãi đến )</strong>}
          title={
            <p className="flex flex-col items-center gap-3 sm:justify-center">
              <p className="flex  items-center gap-3 sm:justify-center">
                <span className="text-[1.4rem] sm:text-[1.3rem]  line-through">
                  50.000đ
                </span>
                <span className="text-[#f47425] text-[1.8rem] sm:text-[1.4rem]  font-semibold">
                  29.000đ/tháng
                </span>
              </p>
              <span className="text-[1.6rem] sm:text-[1.3rem] mr-2">
                🔥 20.000đ/tháng khi mua từ 6 tháng trở lên
              </span>
            </p>
          }
        />
      </div>
    </div>
  );
}

export default BuyCourse;
