import moment from "moment";
import { useEffect, useState, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Form,
  Table,
  Button,
  Descriptions,
  message,
  Popconfirm,
  Select,
} from "antd";
import { Input, Select as SelectAnt } from "antd";

import {
  deleteManyUser,
  deleteUser,
  editUserRequest,
  getAllUsers,
} from "../../../../redux/apiRequest";
import Loading from "../../../SupportTab/Loading";
import { createAxios } from "../../../../redux/createInstance";
import { getAllUsersSuccess } from "../../../../redux/slice/userSlice";
import axios from "axios";
import { toastErr, toastSuccess } from "../../../../redux/slice/toastSlice";
import { v4 as uuid } from "uuid";
import UserGraph from "./UserGraph";

const defaultExpandable = {
  expandedRowRender: (record) => <div>{record.description}</div>,
};

// phần admin không tối ưu với useCallback, useMemo, memo
const MenuUser = ({ currentUser }) => {
  const [moreInfo, setMoreInfo] = useState(0);
  const [isEditMoreInfo, setIsEditMoreInfo] = useState(false);
  const [dataEditMoreInfo, setDataEditMoreInfo] = useState({
    ads: 0,
    vps: 0,
    difference: 0,
  });
  const [countMonth, setCountMonth] = useState(0);
  const [searchSelector, setSearchSelector] = useState("username");
  const [inputSearch, setInputSearch] = useState("");
  const [editUser, setEditUser] = useState(false);
  const [listUsers, setListUsers] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [updatedUser, setUpdatedUser] = useState(null);
  const [dataBuyCourseCurrentMonth, setDataBuyCourseCurrentMonth] =
    useState(null);
  const [newUserEdit, setNewUserEdit] = useState({
    id: "",
    username: "",
    email: "",
    money: "",
  });
  // const [buyCourses, setBuyCourses] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const user = useSelector((state) => state.auth.login?.currentUser);
  const dispatch = useDispatch();
  let axiosJWT = createAxios(user, dispatch, getAllUsersSuccess);
  const [userTest, setUserTest] = useState({
    username: "",
    courses: [],
    month: 1,
  });

  // lấy dữ liệu số người đang online từ server
  // useEffect(() => {
  //   socket.on("userCount", (count) => {
  //     setOnlineUsers(count);
  //   });

  //   return () => {
  //     socket.emit("disconnect", user._id);
  //   };
  // }, []);

  const { TextArea } = Input;

  const options = [
    {
      value: "n1",
      label: "N1",
    },
    {
      value: "n2",
      label: "N2",
    },
    {
      value: "n3",
      label: "N3",
    },
    {
      value: "n4",
      label: "N4",
    },
    {
      value: "n5",
      label: "N5",
    },
    {
      value: "tokutei",
      label: "Tokutei",
    },
  ];

  // MORE INFO THÔNG TIN TIỀN QUẢNG CÁO,VPS VÀ CHÊNH LỆCH
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/tip/moreInfo`)
      .then((res) => {
        setMoreInfo(res.data);
        setDataEditMoreInfo(res.data);
      });
  }, []);

  // select course mua

  // lấy danh sách tài khoản đã đăng kí
  useEffect(() => {
    getAllUsers(user?.accessToken, dispatch, axiosJWT).then((users) => {
      // Lấy thời gian hiện tại
      const currentDate = new Date();
      // Lấy tháng hiện tại (từ 0 đến 11, với 0 là tháng 1)
      const currentMonth = currentDate.getMonth();
      // Chuyển đổi thành tên tháng (từ Jan đến Dec)
      const currentMonthName = new Intl.DateTimeFormat("en", {
        month: "short",
      }).format(currentDate);
      setIsLoading(false);
      setListUsers(users?.users);
      setCountMonth(users?.subData);
      setDataBuyCourseCurrentMonth(
        users?.subData.dataBuyCourse[currentMonthName]
      );
    });
  }, [updatedUser]);

  // thông tin table
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Id",
      dataIndex: "id",
    },
    {
      title: "Admin",
      dataIndex: "admin",
      sorter: (a, b) => a.admin.localeCompare(b.admin),
    },
    {
      title: "Email",
      dataIndex: "email",
      filters: [
        {
          text: "Gmail",
          value: "gmail",
        },
        {
          text: "Email",
          value: "email",
        },
        {
          text: "Icloud",
          value: "icloud",
        },
      ],
      onFilter: (value, record) => {
        const domain = record.email.split("@")[1];
        if (value === "gmail") {
          return domain === "gmail.com";
        } else if (value === "email") {
          return domain === "email.com";
        } else if (value === "icloud") {
          return domain === "icloud.com";
        }
        return false;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Button
          className="flex items-center bg-red-500"
          type="primary"
          onClick={() => {
            if (window.confirm(`Bạn có chắc chắn muốn xóa ${record.name} ?`)) {
              handleDeleteUser(record.id);
            } else {
              // Nếu người dùng chọn Cancel
              // Hủy bỏ hành động của bạn ở đây
            }
          }}
        >
          Delete
        </Button>
      ),
    },
  ];

  const text = "Bạn có chắc chắn muốn sửa?";
  const description = "are you sure...";
  // hàm xác nhận sửa user
  const confirmEdit = () => {
    handleEditUser();
    setEditUser(false);
    // message.info("Sửa thành công");
  };

  // filter kết hợp tìm kiếm
  const filteredData = listUsers.filter((item) => {
    return item[searchSelector]
      .toLowerCase()
      .includes(inputSearch.toLowerCase());
  });

  // data cho table
  const data = [];
  if (filteredData) {
    filteredData.forEach((user, index) => {
      data.push({
        key: index,
        money: user.money,
        name: user.username,
        id: user._id,
        email: user.email,
        courses: user.courses,
        admin: user.isAdmin ? "Admin" : "Thành viên",
        isEdit: false,
        description: (
          <Descriptions
            className="bg-red"
            bordered
            title="Chi tiết tài khoản"
            size="large"
            extra={
              <div className="flex gap-[2rem]">
                {editUser && (
                  <Popconfirm
                    placement="top"
                    title={text}
                    description={description}
                    onConfirm={confirmEdit}
                    okText="Yes"
                    cancelText="No"
                    okButtonProps={{ className: "my-ok-button-class" }}
                  >
                    <Button
                      className="flex items-center bg-green-500"
                      type="primary"
                    >
                      Save
                    </Button>
                  </Popconfirm>
                )}
                <Button
                  className="flex items-center bg-green-500"
                  type="primary"
                  onClick={() => {
                    data[index].isEdit = !data[index].isEdit;

                    setEditUser(!editUser);
                  }}
                >
                  {editUser ? "Cancel" : "Edit"}
                </Button>
              </div>
            }
          >
            <Descriptions.Item label="ID">{user._id}</Descriptions.Item>
            <Descriptions.Item label="Tên">
              {editUser ? (
                <TextArea
                  placeholder={user.username}
                  value={newUserEdit.username}
                  allowClear
                  onChange={(e) => {
                    setNewUserEdit({
                      ...newUserEdit,
                      username: e.target.value,
                    });
                  }}
                />
              ) : (
                user.username
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Email">
              {editUser ? (
                <TextArea
                  placeholder={user.email}
                  value={newUserEdit.email}
                  allowClear
                  onChange={(e) => {
                    setNewUserEdit({
                      ...newUserEdit,
                      email: e.target.value,
                    });
                  }}
                />
              ) : (
                user.email
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Khóa Học">
              {!user.courses
                ? "chưa có khóa học nào..."
                : Object.keys(user.courses).map((course, index) => (
                    <div key={index} className="row flex my-[1rem]">
                      <div className="">
                        <label className="font-bold">{course} :</label>
                      </div>
                      <div className="ml-6 ">
                        <p className="text-[#0062CC] font-bold">
                          {moment(user.courses[course]?.time).format(
                            "DD/MM/YYYY HH:mm"
                          )}
                        </p>
                      </div>
                    </div>
                  ))}
            </Descriptions.Item>
            <Descriptions.Item label="Tiền">
              {editUser ? (
                <TextArea
                  placeholder={`${user.money} $`}
                  allowClear
                  value={newUserEdit.money}
                  onChange={(e) => {
                    setNewUserEdit({
                      ...newUserEdit,
                      money: e.target.value,
                    });
                  }}
                />
              ) : (
                `${user.money} $`
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Chức vụ">
              {user.isAdmin ? "admin" : "thành viên"}
            </Descriptions.Item>
            <Descriptions.Item label="Thông tin chi tiết">
              Thời gian tạo nick:{" "}
              {moment(user.createdAt).format("DD/MM/YYYY HH:mm")}
              <br />
              Sửa đổi gần nhất:{" "}
              {moment(user.updatedAt).format("DD/MM/YYYY HH:mm")}
              <br />
              {/* Tổng số khóa học đã mua: {user.courses.length} */}
            </Descriptions.Item>
          </Descriptions>
        ),
      });
    });
  }
  // phần footer
  const selectedRows = data
    .filter((record, index) => selectedRowKeys.includes(index))
    .map((record) => record.id);

  const defaultFooter = () => {
    return (
      <div>
        <p>
          Tổng số tài khoản :{" "}
          <span style={{ color: "red", fontWeight: 600, fontSize: "1.8rem" }}>
            {listUsers.length}
          </span>
        </p>
        <p style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
          Tổng số tài khoản đang được chọn :
          <span style={{ color: "red", fontWeight: 600, fontSize: "1.8rem" }}>
            {" "}
            {selectedRows.length}
          </span>
          {selectedRows.length > 0 && (
            <Button
              className="flex items-center bg-red-500"
              type="primary"
              onClick={() => {
                if (window.confirm(`Bạn có chắc chắn muốn xóa ?`)) {
                  deleteManyUser(
                    currentUser.accessToken,
                    selectedRows,
                    axiosJWT
                  );
                  setUpdatedUser(selectedRows);
                  setSelectedRowKeys([]);
                } else {
                  // Nếu người dùng chọn Cancel
                  // Hủy bỏ hành động của bạn ở đây
                }
              }}
            >
              Xóa Hàng loạt
            </Button>
          )}
        </p>
      </div>
    );
  };

  const tableColumns = columns.map((item) => ({
    ...item,
  }));

  // Phần xử lí khi người dùng nháy vào phần mở rộng
  const handleExpand = (expanded, record) => {
    // "expanded" (boolean) và "record" (đối tượng dữ liệu của hàng được mở rộng)
    setEditUser(false);
    setNewUserEdit({
      id: record.id,
      username: record.name,
      email: record.email,
      money: record.money,
      courses: record.courses,
    });
    setSelectedRecord(expanded ? record : null);
  };

  // phần xác định và thêm class cho thẻ mở rộng
  const isRecordSelected = (record) => {
    return selectedRecord && selectedRecord.id === record.id;
  };
  // phần xác định và thêm class cho thẻ mở rộng
  const getRowClassName = (record, index) => {
    return isRecordSelected(record) ? "userAdmin__active" : "";
  };

  //phần xét tài khoản test
  const handleSetUserTest = () => {
    if (userTest.username) {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/user/setTestUser`,
          // {
          //   username: userTest,
          //   admin: user.username,
          // },
          userTest,
          {
            headers: {
              token: `Bearer ${user.accessToken}`,
            },
          }
        )
        .then((res) => {
          dispatch(toastSuccess(res.data));
          setUpdatedUser(uuid());
        })
        .catch((err) => dispatch(toastErr(err.response.data)));
    }
  };

  // Phần xử lí cập nhật chỉnh sử user
  const handleEditUser = () => {
    // let courses = buyCourses.split(",");
    // axios
    //   .post(
    //     `${process.env.REACT_APP_BACKEND_URL}/user/edit`,
    //     {
    //       username: newUserEdit.username,
    //       // courses: courses,
    //     },
    //     {
    //       headers: { token: `Bearer ${user.accessToken}` },
    //     }
    //   )
    //   .then((response) => dispatch(toastSuccess(response.data)))
    //   .catch((err) => dispatch(toastErr(err.response.data)));
    // cận thận vì khi nháy nút edit thì tất cả các thẻ sẽ chuyển sang chế độ isedit bằng true nên. có thể fix bằng cách chỉnh lại logic của onClick nút edit. Chúng ta sẽ ẩn hoặc mở nút Save của chính thẻ đó
    editUserRequest(currentUser.accessToken, newUserEdit, axiosJWT)
      .then((response) => dispatch(toastSuccess("Chỉnh sửa thành công")))
      .catch((err) => dispatch(toastErr("Chỉnh sửa thất bại")));
    setUpdatedUser(newUserEdit);
  };
  //phần xử lí xóa user
  const handleDeleteUser = (id) => {
    deleteUser(currentUser.accessToken, id, axiosJWT)
      .then((response) => dispatch(toastSuccess("Chỉnh sửa thành công")))
      .catch((err) => dispatch(toastErr("Chỉnh sửa thất bại")));
    setUpdatedUser(id);
  };
  const tableProps = {
    expandable: {
      ...defaultExpandable,
      onExpand: handleExpand,
      expandRowByClick: false,
    },
    footer: defaultFooter,
    rowSelection: {
      type: "checkbox",
      selectedRowKeys,
      onChange: setSelectedRowKeys,
    },
    rowClassName: getRowClassName,
  };

  //format số tiền
  const VND = new Intl.NumberFormat("vi-VN", {
    style: "currency",
    currency: "VND",
  });

  return (
    <>
      {isLoading && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <Loading />
        </div>
      )}
      <div className="flex flex-col gap-2 pt-9">
        <strong className="flex items-center">
          Số liệu thống kê trong
          <span className="text-red-500 text-[1.7rem] ml-2">
            tháng {new Date().getMonth() + 1}
          </span>
        </strong>
        <p>
          Số tháng đã bán:{" "}
          <span className="font-bold text-red-500">
            {dataBuyCourseCurrentMonth?.month}
          </span>
        </p>
        <p>
          Số người dùng đã mua :{" "}
          <span className="font-bold text-red-500">
            {dataBuyCourseCurrentMonth?.people.length}
          </span>
        </p>
        <p>
          Thu nhập ước tính:{" "}
          <span className="font-bold text-red-500">
            {VND.format(dataBuyCourseCurrentMonth?.month * 20000)}
          </span>
        </p>

        <p>
          Thời gian cập nhật mới nhất:
          <span className="font-bold text-red-500">
            {" "}
            {moment(countMonth.updatedAt).format("DD/MM/YYYY HH:mm")}
          </span>
        </p>
      </div>
      <div className="shadow stats">
        <div className="stat">
          <div className="stat-figure text-primary">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              className="inline-block w-8 h-8 stroke-current"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
              ></path>
            </svg>
          </div>
          <div className="stat-title">Tiền chạy quảng cáo</div>
          {isEditMoreInfo ? (
            <input
              value={dataEditMoreInfo.ads}
              onChange={(e) =>
                setDataEditMoreInfo({
                  ...dataEditMoreInfo,
                  ads: e.target.value,
                })
              }
              type="number"
              placeholder="Type here"
              className="w-full max-w-xs input input-bordered"
            />
          ) : (
            <div className="stat-value text-primary">
              {VND.format(moreInfo?.ads)}
            </div>
          )}

          <div
            className={`btn ${isEditMoreInfo ? "btn-success" : ""}`}
            onClick={() => {
              if (isEditMoreInfo) {
                axios
                  .post(
                    `${process.env.REACT_APP_BACKEND_URL}/tip/editMoreInfo`,
                    dataEditMoreInfo
                  )
                  .then((res) => {
                    dispatch(toastSuccess("chỉnh sửa thành công"));
                    setMoreInfo(res.data);
                    setIsEditMoreInfo(!isEditMoreInfo);
                  })
                  .catch(() => dispatch(toastErr("chỉnh sửa thất bại")));
              } else {
                setIsEditMoreInfo(!isEditMoreInfo);
              }
            }}
          >
            {isEditMoreInfo ? "Lưu" : "Chỉnh sửa"}
          </div>
        </div>

        <div className="stat">
          <div className="stat-figure text-secondary">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              className="inline-block w-8 h-8 stroke-current"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13 10V3L4 14h7v7l9-11h-7z"
              ></path>
            </svg>
          </div>
          <div className="stat-title">Tiền VPS</div>
          {isEditMoreInfo ? (
            <input
              value={dataEditMoreInfo.vps}
              onChange={(e) =>
                setDataEditMoreInfo({
                  ...dataEditMoreInfo,
                  vps: e.target.value,
                })
              }
              type="number"
              placeholder="Type here"
              className="w-full min-w-[100px] max-w-xs input input-bordered"
            />
          ) : (
            <div className="stat-value text-primary">
              {VND.format(moreInfo?.vps)}
            </div>
          )}

          <div
            className={`btn ${isEditMoreInfo ? "btn-success" : ""}`}
            onClick={() => {
              if (isEditMoreInfo) {
                axios
                  .post(
                    `${process.env.REACT_APP_BACKEND_URL}/tip/editMoreInfo`,
                    dataEditMoreInfo
                  )
                  .then((res) => {
                    dispatch(toastSuccess("chỉnh sửa thành công"));
                    setMoreInfo(res.data);
                    setIsEditMoreInfo(!isEditMoreInfo);
                  })
                  .catch(() => dispatch(toastErr("chỉnh sửa thất bại")));
              } else {
                setIsEditMoreInfo(!isEditMoreInfo);
              }
            }}
          >
            {isEditMoreInfo ? "Lưu" : "Chỉnh sửa"}
          </div>
        </div>
        <div className="stat">
          <div className="stat-figure text-secondary">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              className="inline-block w-8 h-8 stroke-current"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13 10V3L4 14h7v7l9-11h-7z"
              ></path>
            </svg>
          </div>
          <div className="stat-title">Tiền chênh lệch (20k - 29k)</div>
          {isEditMoreInfo ? (
            <input
              value={dataEditMoreInfo.difference}
              onChange={(e) =>
                setDataEditMoreInfo({
                  ...dataEditMoreInfo,
                  difference: e.target.value,
                })
              }
              type="number"
              placeholder="Type here"
              className="w-full max-w-xs input input-bordered"
            />
          ) : (
            <div className="stat-value text-primary">
              {VND.format(moreInfo?.difference)}
            </div>
          )}

          <div
            className={`btn ${isEditMoreInfo ? "btn-success" : ""}`}
            onClick={() => {
              if (isEditMoreInfo) {
                axios
                  .post(
                    `${process.env.REACT_APP_BACKEND_URL}/tip/editMoreInfo`,
                    dataEditMoreInfo
                  )
                  .then((res) => {
                    dispatch(toastSuccess("chỉnh sửa thành công"));
                    setMoreInfo(res.data);
                    setIsEditMoreInfo(!isEditMoreInfo);
                  })
                  .catch(() => dispatch(toastErr("chỉnh sửa thất bại")));
              } else {
                setIsEditMoreInfo(!isEditMoreInfo);
              }
            }}
          >
            {isEditMoreInfo ? "Lưu" : "Chỉnh sửa"}
          </div>
        </div>

        <div className="stat">
          <div className="stat-figure text-secondary">
            <div className="avatar online">
              <div className="w-16 rounded-full">
                <img
                  alt="img jlpt"
                  src="https://scontent.fsgn2-4.fna.fbcdn.net/v/t39.30808-6/338382149_944472253406315_3947395889329509877_n.jpg?_nc_cat=101&ccb=1-7&_nc_sid=a2f6c7&_nc_ohc=049vJSZ-LFIAX8UGpzF&_nc_ht=scontent.fsgn2-4.fna&oh=00_AfBTH7S9llL-79SJS0idzBGRdWu11Zxi0vrbZMJHEx_QAA&oe=6510E001"
                />
              </div>
            </div>
          </div>
          <div className="stat-value">86%</div>
          <div className="stat-title">Tasks done</div>
          <div className="stat-desc text-secondary">31 tasks remaining</div>
        </div>
      </div>
      <div className="shadow stats">
        <div className="stat">
          <div className="stat-figure text-secondary">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              className="inline-block w-8 h-8 stroke-current"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13 10V3L4 14h7v7l9-11h-7z"
              ></path>
            </svg>
          </div>
          <div className="stat-title">Tổng số tháng đã bán</div>
          <div className="stat-value text-secondary">{countMonth?.month}</div>
          {/* <div className="stat-desc">21% more than last month</div> */}
        </div>
        <div className="stat">
          <div className="stat-figure text-primary">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              className="inline-block w-8 h-8 stroke-current"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
              ></path>
            </svg>
          </div>

          <div className="stat-title">Tổng số tiền đã bán</div>
          <div className="stat-value text-primary">
            {VND.format(countMonth?.month * 20000 + moreInfo?.difference)}
          </div>
          <div className="stat-desc text-[1.2rem] mt-3">
            số tháng * 20k + tiền chênh lêch
          </div>
        </div>

        <div className="stat">
          <div className="stat-figure text-secondary">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              className="inline-block w-8 h-8 stroke-current"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13 10V3L4 14h7v7l9-11h-7z"
              ></path>
            </svg>
          </div>
          <div className="stat-title">Lợi nhuận ước chừng</div>
          <div className="stat-value text-secondary">
            {" "}
            {VND.format(
              countMonth?.month * 20000 - moreInfo?.ads - moreInfo?.vps
            )}
          </div>
          <div className="stat-desc text-[1.2rem] mt-3">
            tổng tiền - chi phí
          </div>
        </div>
      </div>

      {countMonth && listUsers && (
        <UserGraph listUsers={listUsers} dataBuyCourse={countMonth} />
      )}

      <div className="my-[2rem]">
        <Form.Item label="Fields" className="w-[30rem]">
          <Select
            value={searchSelector}
            onChange={(value) => {
              setSearchSelector(value);
            }}
          >
            <Select.Option value="username">username</Select.Option>
            <Select.Option value="email">email</Select.Option>

            <Select.Option value="_id">id</Select.Option>
          </Select>
        </Form.Item>
        <div className="flex h-[3rem] gap-5 items-center">
          <label htmlFor="inputSearch">Search: </label>
          <input
            id="inputSearch"
            className="border border-[#333] outline-none h-full w-[40rem]  rounded-xl px-3"
            value={inputSearch}
            onChange={(e) => {
              setInputSearch(e.target.value);
            }}
          ></input>
        </div>
        <div className="flex mt-5 h-[3rem] gap-5 items-center">
          <button
            onClick={handleSetUserTest}
            className="px-2 transition-opacity border rounded-2xl bg-slate-300 active:opacity-25"
          >
            Xác nhận mua:{" "}
          </button>
          <input
            id="inputSearch"
            className="border border-[#333] outline-none h-full w-[40rem]  rounded-xl px-3"
            placeholder="Điền username..."
            value={userTest.username}
            onChange={(e) => {
              setUserTest({
                ...userTest,
                username: e.target.value,
              });
              setInputSearch(e.target.value);
            }}
          ></input>
          <Select
            mode="tags"
            size={"middle"}
            placeholder="Điền khóa học"
            onChange={(value) => {
              setUserTest({
                ...userTest,
                courses: [...value],
              });
            }}
            style={{
              width: "30%",
            }}
            options={options}
          />
          <input
            className="border border-[#333] outline-none h-full w-[20rem]  rounded-xl px-3"
            placeholder="Điền số tháng..."
            type="number"
            value={userTest.month}
            onChange={(e) => {
              setUserTest({
                ...userTest,
                month: e.target.value,
              });
            }}
          ></input>
        </div>
      </div>
      <Form
        layout="inline"
        className="components-table-demo-control-bar"
        style={{
          marginBottom: 16,
        }}
      ></Form>
      <Table
        {...tableProps}
        pagination={{
          position: ["none", "bottomRight"],
        }}
        columns={tableColumns}
        dataSource={data}
        scroll={{}}
      />
    </>
  );
};
export default memo(MenuUser);
